import React from "react"
import GlitchText from 'react-glitch-effect/core/GlitchText'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { LinkIn } from '../components/Link'
import Layout from '../layouts/Website'
import Paper from '../components/Paper'
import Heading from '../components/Heading'

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '100px 100px 100px 100px',
    padding: '10px',
    boxSizing: 'border-box',
    borderStyle: 'dotted',
    borderWidth: '2px',
    borderColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      margin: '100px 10px 100px 10px',
    },
  },
}));

const IndexPage = () => {
  const classes = useStyles();
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
              <Heading title='Software Engineering' />

              <Typography variant='h6' component='h3'
              >
                Web Development
              </Typography>
              <Typography variant='h6' component='h3'>
                Digital Security
              </Typography>
              <Typography variant='h6' component='h3'
                paragraph
              >
                IT Research & Consulting
              </Typography>
              <Typography variant='subtitle1' component='h3'>
                <LinkIn to='/console'>
                 <b>PROJECT MANAGEMENT CONSOLE</b>
              </LinkIn>
              </Typography>
              <Box className={classes.box}>
              <Typography variant='subtitle2' component='p'>
                APPS THAT MATCH YOUR PARTICULAR NEEDS
              </Typography>
              <GlitchText>
              <LinkIn to='/contact'
                color='inherit'
              >
                Find out how we can help {'>'}
                </LinkIn>
                </GlitchText>
              </Box>
          </Paper>
        </Grid>
    </Layout>
  )
}

export default IndexPage
